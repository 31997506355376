<script lang="ts">
import { Product } from '~/models/arbitrary';
import { ClientType, RegistrationStatus, TaxTreatment } from '~/models/clients';

type SupportedCurrencies = 'CZK' | 'EUR' | 'PLN';
type SupportedAccountTypes = `${Lowercase<
  Extract<keyof typeof ClientType, 'Client' | 'Baby' | 'Corporate'>
>}-default`;
type AccountId =
  | `${SupportedAccountTypes}`
  | 'client-dip'
  | 'client-dip-CZK'
  | `${SupportedAccountTypes}-${SupportedCurrencies}`;

type Props = {
  showSection: boolean;
  expanded: boolean;
};
</script>

<script setup lang="ts">
import MoneyAmount from '~/components/common/MoneyAmount.vue';
import DropdownItem from '~/components/layout/internal/shared/DropdownItem.vue';
import IconGallery from '~/components/layout/internal/icons/Gallery.vue';
import IconPortu from '~/components/layout/internal/icons/Portu.vue';
import IconOpportunity from '~/components/layout/internal/icons/Opportunity.vue';
import IconBabyDefault from '~/components/layout/internal/icons/accounts/BabyDefault.vue';
import IconBabyDefaultCZK from '~/components/layout/internal/icons/accounts/BabyDefaultCZK.vue';
import IconBabyDefaultEUR from '~/components/layout/internal/icons/accounts/BabyDefaultEUR.vue';
import IconBabyDefaultPLN from '~/components/layout/internal/icons/accounts/BabyDefaultPLN.vue';
import IconClientDefault from '~/components/layout/internal/icons/accounts/ClientDefault.vue';
import IconClientDefaultCZK from '~/components/layout/internal/icons/accounts/ClientDefaultCZK.vue';
import IconClientDefaultEUR from '~/components/layout/internal/icons/accounts/ClientDefaultEUR.vue';
import IconClientDefaultPLN from '~/components/layout/internal/icons/accounts/ClientDefaultPLN.vue';
import IconClientDIP from '~/components/layout/internal/icons/accounts/ClientDIP.vue';
import IconClientDIPCZK from '~/components/layout/internal/icons/accounts/ClientDIPCZK.vue';
import IconCorporateDefault from '~/components/layout/internal/icons/accounts/CorporateDefault.vue';
import IconCorporateDefaultCZK from '~/components/layout/internal/icons/accounts/CorporateDefaultCZK.vue';
import IconCorporateDefaultEUR from '~/components/layout/internal/icons/accounts/CorporateDefaultEUR.vue';
import IconCorporateDefaultPLN from '~/components/layout/internal/icons/accounts/CorporateDefaultPLN.vue';
import IconAddAccount from '~/components/layout/internal/icons/AddAccount.vue';
import { openLink } from '~/lib/open-link';

defineProps<Props>();

const { t } = useI18n();
const localePath = useLocalePath();
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const ACCOUNT_ID_TO_COMPONENT = {
  'client-default': IconClientDefault,
  'client-default-CZK': IconClientDefaultCZK,
  'client-default-EUR': IconClientDefaultEUR,
  'client-default-PLN': IconClientDefaultPLN,
  'client-dip': IconClientDIP,
  'client-dip-CZK': IconClientDIPCZK,
  'client-pension': IconClientDIP,
  'client-pension-EUR': IconClientDIPCZK,
  'baby-default': IconBabyDefault,
  'baby-default-CZK': IconBabyDefaultCZK,
  'baby-default-EUR': IconBabyDefaultEUR,
  'baby-default-PLN': IconBabyDefaultPLN,
  'corporate-default': IconCorporateDefault,
  'corporate-default-CZK': IconCorporateDefaultCZK,
  'corporate-default-EUR': IconCorporateDefaultEUR,
  'corporate-default-PLN': IconCorporateDefaultPLN,
} as const;
const hasPortuAccount = computed(() =>
  user.value.ClientAccounts.some((account) => account.Product === Product.Algo),
);
const hasOpportunityAccount = computed(() =>
  user.value.ClientAccounts.some((account) => account.Product === Product.Opportunity),
);
const opportunityButtonText = computed(() =>
  t(`layout.internal.navigation.profile.${hasOpportunityAccount.value ? 'go-to' : 'register'}`, {
    product: t('layout.internal.navigation.profile.products.opportunity'),
  }),
);
const galleryBalances = computed(() =>
  (
    getBalance(Product.Gallery, () => [
      {
        currency: 'CZK',
        balance: 0,
      },
    ]) ?? []
  ).filter((balance) => balance.currency === 'CZK'),
);
const opportunityBalances = computed(() =>
  (getBalance(Product.Opportunity) ?? []).filter((balance) => balance.currency === 'CZK'),
);
const portuAccounts = computed(() => {
  const accounts = (user.value.ClientAccounts || []).filter(
    (account) => account.Product === Product.Algo,
  );

  return accounts
    .filter(
      (account) =>
        !(
          account.ClientType === ClientType.Baby &&
          account.RegistrationStatus === RegistrationStatus.InProgress
        ),
    )
    .map((account) => {
      let id = `${ClientType[account.ClientType!].toLowerCase()}-${TaxTreatment[
        account.TaxTreatment
      ].toLowerCase()}`;
      if (account.Currency !== user.value.BaseDefaultCurrency) {
        id += `-${account.Currency}`;
      }

      return {
        id: id as AccountId,
        name:
          account.RegistrationStatus === RegistrationStatus.InProgress && account.Name === ''
            ? t('layout.internal.navigation.profile.finish-registration')
            : account.Name,
        currency: account.Currency,
        balance: account.TotalAmountPerCurrency[account.Currency],
      };
    });
});

const getBalance = (
  product: Product,
  emptyBalanceProvider?: () => { currency: string; balance: number }[] | undefined,
) => {
  const accounts = (user.value.ClientAccounts || []).filter(
    (account) =>
      account.RegistrationStatus !== RegistrationStatus.InProgress && account.Product === product,
  );
  if (accounts.length > 0) {
    const account = accounts[0];

    return Object.entries(account.TotalAmountPerCurrency).map(([currency, balance]) => ({
      currency,
      balance,
    }));
  }

  return emptyBalanceProvider ? emptyBalanceProvider() : undefined;
};
const goToPortu = () => {
  redirectToExternalUrl(Product.Algo);
};
const goToOpportunity = () => {
  redirectToExternalUrl(Product.Opportunity);
};
const redirectToExternalUrl = async (product: Product) => {
  const response = await authStore.getExternalLoginUrl({ redirectUrl: '', product });

  openLink({ url: response.Link });
};
</script>

<template>
  <DropdownItem
    v-if="showSection"
    collapsible
    component="section"
    class="accounts"
    :expanded="expanded"
  >
    <DropdownItem active disabled component="button" level="2" class="account">
      <template #icon>
        <IconGallery />
      </template>

      <div class="account__data">
        <span>{{
          user.RegistrationStatus === RegistrationStatus.InProgress
            ? $t('layout.internal.navigation.profile.finish-registration')
            : $t('layout.internal.navigation.profile.go-to', {
                product: $t('layout.internal.navigation.profile.products.gallery'),
              })
        }}</span>

        <MoneyAmount
          v-for="balance in galleryBalances"
          :key="balance.currency"
          :currency="balance.currency"
          :amount="balance.balance"
        />
      </div>
    </DropdownItem>

    <template v-if="hasPortuAccount">
      <DropdownItem
        v-for="account in portuAccounts"
        :key="account.id"
        component="button"
        level="2"
        class="account account--portu account--external"
        @click="goToPortu"
      >
        <template #icon>
          <component :is="ACCOUNT_ID_TO_COMPONENT[account.id]" />
        </template>

        <div class="account__data">
          <span>{{ account.name }}</span>

          <span class="account__type">{{
            $t(`layout.internal.navigation.accounts.badge.${account.id}`)
          }}</span>

          <MoneyAmount :currency="account.currency" :amount="account.balance" />
        </div>
      </DropdownItem>
    </template>

    <DropdownItem
      v-else
      component="button"
      level="2"
      class="account account--portu account--external"
      @click="goToPortu"
    >
      <template #icon>
        <IconPortu />
      </template>

      <div class="account__data">
        <span>{{
          $t('layout.internal.navigation.profile.register', {
            product: $t('layout.internal.navigation.profile.products.algo'),
          })
        }}</span>
      </div>
    </DropdownItem>

    <DropdownItem
      component="button"
      level="2"
      class="account account--opportunity account--external"
      @click="goToOpportunity"
    >
      <template #icon>
        <IconOpportunity />
      </template>

      <div class="account__data">
        <span>{{ opportunityButtonText }}</span>

        <template v-if="hasOpportunityAccount">
          <MoneyAmount
            v-for="balance in opportunityBalances"
            :key="balance.currency"
            :currency="balance.currency"
            :amount="balance.balance"
          />
        </template>
      </div>
    </DropdownItem>

    <DropdownItem component="link" level="2" :link="localePath('dashboard-profile-add-account')">
      <IconAddAccount />

      <span>{{ $t('layout.internal.navigation.profile.create-another-account') }}</span>
    </DropdownItem>

    <DropdownItem component="divider" />
  </DropdownItem>
</template>

<style lang="scss" scoped>
.account {
  .account__data {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .account__type {
    font-size: var(--font-size-xs);
    font-weight: 400;
    color: var(--gray-dark);
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :deep() {
    .money-amount-value {
      font-size: var(--font-size-xs);
      font-weight: 500;
      color: var(--black);

      > span {
        line-height: inherit;
        font-size: inherit;
        font-weight: inherit;
      }
    }
  }

  &.account--external {
    .account__data span:first-of-type {
      display: inline-flex;
    }

    span:not(.account__type)::after {
      content: '';

      display: inline-block;
      justify-self: flex-start;

      margin-left: 0.125rem;

      width: 0.625rem;
      height: 0.625rem;

      -webkit-mask: url(https://cdn.portu.cz/gallery/images/redesign/external.svg) center
        center/contain no-repeat;
      mask: url(https://cdn.portu.cz/gallery/images/redesign/external.svg) center center/contain
        no-repeat;

      background-color: currentColor;
    }
  }

  &.account--portu {
    &:hover {
      color: var(--green-basic);
    }
  }

  &.account--opportunity {
    &:hover {
      color: #82328c;
    }
  }
}
</style>
