<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 21H3V2m14.25 4.75h3.8m0 0v3.8m0-3.8L16.3 11.5l-3.886-1.815L6.8 15.3"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
</template>
