<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.25 12c0 6.213-5.037 11.25-11.25 11.25S.75 18.213.75 12 5.787.75 12 .75 23.25 5.787 23.25 12Z"
      stroke="#000"
      stroke-width="1.5"
      fill="#FFE76E"
    />
    <path
      d="M16.2 7.528a6 6 0 1 0 0 8.944M5 13.8h7.31M5 10.2h7.2"
      stroke="#000"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
