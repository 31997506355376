<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 22v-2.59a4.91 4.91 0 0 1 4.91-4.91h8.18A4.91 4.91 0 0 1 21 19.41V22M17 7.5a5 5 0 0 1-2.86 4.52 5 5 0 0 1-2.14.48 5 5 0 0 1-3.87-1.83A5 5 0 0 1 7 7.5a5 5 0 0 1 10 0Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
</template>
