<script setup lang="ts">
import Dropdown from '~/components/layout/internal/shared/Dropdown.vue';
import DropdownItem from '~/components/layout/internal/shared/DropdownItem.vue';
import Accounts from '~/components/layout/internal/shared/Accounts.vue';
import IconHamburger from '~/components/layout/internal/icons/Hamburger.vue';
import IconAccounts from '~/components/layout/internal/icons/Accounts.vue';
import IconChevronDown from '~/components/icons/ChevronDown.vue';
import IconProfile from '~/components/layout/internal/icons/Profile.vue';
import IconBank from '~/components/layout/internal/icons/Bank.vue';
import IconDocuments from '~/components/layout/internal/icons/Documents.vue';
import IconSecurity from '~/components/layout/internal/icons/Security.vue';
import IconPreferences from '~/components/layout/internal/icons/Preferences.vue';
import IconPresents from '~/components/layout/internal/icons/Presents.vue';

const router = useRouter();
const localePath = useLocalePath();
const layoutStore = useLayoutStore();
const authStore = useAuthStore();

const opened = ref(true);

function logout() {
  authStore.logout();

  router.push(localePath('/'));
}

watch(opened, () => {
  if (!opened.value) {
    layoutStore.setDefaultOpenedMenuSection();
  }
});
</script>

<template>
  <Dropdown alignment="right" class="hamburger-menu">
    <template #trigger>
      <div class="hamburger">
        <IconHamburger />
      </div>
    </template>

    <template #default="slotProps">
      <DropdownItem component="button" @click="opened = !opened">
        <template #icon>
          <IconAccounts />
        </template>

        <div class="collapsible__title">
          <span>{{ $t('layout.internal.navigation.accounts.title') }}</span>

          <IconChevronDown class="icon--chevron" :class="{ 'icon--rotated': opened }" />
        </div>
      </DropdownItem>

      <Accounts :show-section="slotProps.opened" :expanded="opened" />

      <DropdownItem component="link" :link="localePath('dashboard-profile')">
        <template #icon>
          <IconProfile />
        </template>

        <span>{{ $t('layout.links.profile') }}</span>
      </DropdownItem>

      <DropdownItem component="link" :link="localePath('dashboard-profile-bank-accounts')">
        <template #icon>
          <IconBank />
        </template>

        <span>{{ $t('layout.links.bank-accounts') }}</span>
      </DropdownItem>

      <DropdownItem
        component="link"
        :link="localePath('dashboard-profile-statements-and-documents')"
      >
        <template #icon>
          <IconDocuments />
        </template>

        <span>{{ $t('layout.links.statements-and-documents') }}</span>
      </DropdownItem>

      <DropdownItem component="link" :link="localePath('dashboard-profile-security')">
        <template #icon>
          <IconSecurity />
        </template>

        <span>{{ $t('layout.links.security') }}</span>
      </DropdownItem>

      <DropdownItem component="link" :link="localePath('dashboard-profile-email-preferences')">
        <template #icon>
          <IconPreferences />
        </template>

        <span>{{ $t('layout.links.email-preferences') }}</span>
      </DropdownItem>

      <DropdownItem component="link" :link="localePath('dashboard-gifts-and-benefits-vouchers')">
        <template #icon>
          <IconPresents />
        </template>

        <span>{{ $t('layout.links.vouchers') }}</span>
      </DropdownItem>

      <Button variant="outlined" theme="light" @click="logout">{{
        $t('layout.internal.navigation.profile.log-out')
      }}</Button>
    </template>
  </Dropdown>
</template>

<style lang="scss" scoped>
.hamburger {
  position: relative;

  height: 15px;

  svg {
    display: block;
  }
}

.collapsible__title {
  display: flex;
  align-items: center;
}

.button {
  margin-top: 0.5rem;
}
</style>
