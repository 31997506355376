<script setup lang="ts">
import Logo from '~/components/common/Logo.vue';
import Spacer from '~/components/common/Spacer.vue';
import DepositMoney from '~/components/layout/internal/shared/DepositMoney.vue';
import Hamburger from '~/components/layout/internal/mobile/Hamburger.vue';

const localePath = useLocalePath();
const authStore = useAuthStore();
const advertisingStore = useAdvertisingStore();
const { user } = storeToRefs(authStore);

onMounted(() => {
  if (!user.value.AgreedGallerySecTradeTerms) {
    return;
  }

  advertisingStore.getTrades({});
});
</script>

<template>
  <div class="container">
    <div class="logo">
      <NuxtLink :to="localePath('dashboard')">
        <Logo :alt="$t('layout.external.navigation.logo-alt')" />
      </NuxtLink>
    </div>

    <Spacer />

    <div class="items">
      <DepositMoney />

      <Hamburger />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  position: relative;

  display: flex;
  align-items: stretch;
  gap: 0.5rem;

  padding-top: 1rem;
  padding-bottom: 1rem;

  .logo:not(img) {
    display: inline-flex;
    align-items: center;

    width: 7.5rem;

    a {
      display: inherit;
    }
  }

  @media only screen and (min-width: 1199.98px) {
    display: none;
  }
}

.items {
  --menu-item-padding: 0 0.5rem;

  display: flex;
  flex-direction: row;
}
</style>
