<script lang="ts">
type Props = {
  showSection: boolean;
  expanded: boolean;
};
</script>

<script setup lang="ts">
import DropdownItem from '~/components/layout/internal/shared/DropdownItem.vue';
import Badge from '~/components/common/Badge.vue';

defineProps<Props>();

const localePath = useLocalePath();
const authStore = useAuthStore();
const advertisingStore = useAdvertisingStore();
const { user } = storeToRefs(authStore);
const { buyingTrades } = storeToRefs(advertisingStore);

const hasTradesToConfirm = computed(
  () => !!user.value.AgreedGallerySecTradeTerms && buyingTrades.value.length > 0,
);
</script>

<template>
  <DropdownItem
    v-if="showSection"
    collapsible
    component="section"
    class="advertising-space"
    :expanded="expanded"
  >
    <DropdownItem component="link" level="2" :link="localePath('dashboard-advertising-space')">
      <span>{{ $t('layout.links.advertising-space') }}</span>
    </DropdownItem>

    <DropdownItem
      component="link"
      level="2"
      :link="localePath('dashboard-advertising-space-my-advertisements')"
    >
      <span>{{ $t('layout.links.my-advertisements') }}</span>
    </DropdownItem>

    <DropdownItem
      component="link"
      level="2"
      :link="localePath('dashboard-advertising-space-advertisement-new')"
    >
      <span>{{ $t('layout.links.add-advertisement') }}</span>
    </DropdownItem>

    <DropdownItem
      component="link"
      level="2"
      :link="localePath('dashboard-advertising-space-trades')"
    >
      <span>{{ $t('layout.links.advertising-space-trades') }}</span>

      <Badge v-if="hasTradesToConfirm" color="red">{{ buyingTrades.length }}</Badge>
    </DropdownItem>
  </DropdownItem>
</template>

<style lang="scss" scoped>
.dropdown__item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .badge {
    margin: 0;
  }
}

.badge {
  --border-color: var(--red-basic);
  --background-color: var(--red-basic);

  margin-left: 0.25rem;

  color: var(--white);
}
</style>
