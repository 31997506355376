<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.25 12c0 6.213-5.037 11.25-11.25 11.25S.75 18.213.75 12 5.787.75 12 .75 23.25 5.787 23.25 12Z"
      fill="#FFE76E"
      stroke="#000"
      stroke-width="1.5"
    />
    <path
      d="M12.722 13.778h3.467c.809 0 1.213 0 1.522.157.272.139.493.36.632.631.157.31.157.714.157 1.523V21m-5.778 1.5V10.311c0-.809 0-1.213-.157-1.522a1.45 1.45 0 0 0-.631-.632C11.624 8 11.22 8 10.41 8h-2.6c-.809 0-1.213 0-1.522.157-.272.139-.493.36-.632.632-.157.309-.157.713-.157 1.522V21"
      stroke="#000"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
