<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.88 15.471a4.487 4.487 0 0 1-2.85 1 4.488 4.488 0 0 1-3.158-1.31A4.518 4.518 0 0 1 7.545 12a4.518 4.518 0 0 1 1.317-3.18A4.488 4.488 0 0 1 12.03 7.5a4.488 4.488 0 0 1 3.169 1.32A4.517 4.517 0 0 1 16.515 12m.559 8a9.447 9.447 0 0 1-12.201-1.71 9.53 9.53 0 0 1-.189-12.359 9.472 9.472 0 0 1 3.548-2.66 9.441 9.441 0 0 1 4.367-.75 9.636 9.636 0 0 1 6.368 3.113A9.698 9.698 0 0 1 21.5 12.27v.23c0 4-4.984 5-4.984 1V7"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
</template>
