<script lang="ts">
type Level = '1' | '2';
type Props = {
  level: Level;
};
</script>

<script setup lang="ts">
import SkeletonMoneyAmount from '~/components/common/SkeletonMoneyAmount.vue';
import DropdownItem from '~/components/layout/internal/shared/DropdownItem.vue';
import MoneyAmount from '~/components/common/MoneyAmount.vue';

defineProps<Props>();

const localePath = useLocalePath();
const portfoliosStore = usePortfoliosStore();
const { loadingPortfolios, wallet } = storeToRefs(portfoliosStore);

const availableMoney = computed(
  () => wallet.value!.AvailableForWithdrawalByCurrency.find((money) => money.Currency === 'CZK')!,
);
</script>

<template>
  <DropdownItem v-if="loadingPortfolios" component="text" :level="level">
    <SkeletonMoneyAmount />
  </DropdownItem>

  <template v-else>
    <DropdownItem
      not-activable
      component="link"
      :level="level"
      :link="localePath('dashboard-finance-transactions-history')"
    >
      <MoneyAmount
        :currency="availableMoney.Currency"
        :amount="availableMoney.Value"
        :title="$t(`transactions-history.wallet.money-amount.title.${availableMoney.Currency}`)"
      />
    </DropdownItem>
  </template>
</template>

<style lang="scss" scoped>
.money-amount {
  color: var(--black);

  :deep(.money-amount-value > *) {
    font-size: var(--font-size-sm);
    font-weight: 700;
  }
}

.money-amount + .money-amount {
  margin-top: 0.5rem;
}
</style>
