<script lang="ts">
type Props = {
  showSection: boolean;
  expanded: boolean;
};
</script>

<script setup lang="ts">
import DropdownItem from '~/components/layout/internal/shared/DropdownItem.vue';

defineProps<Props>();

const localePath = useLocalePath();
</script>

<template>
  <DropdownItem
    v-if="showSection"
    collapsible
    component="section"
    class="investments"
    :expanded="expanded"
  >
    <DropdownItem
      component="link"
      level="2"
      :link="localePath('dashboard-investments-my-investments')"
    >
      <span>{{ $t('layout.links.my-investments') }}</span>
    </DropdownItem>

    <DropdownItem
      component="link"
      level="2"
      :link="localePath('dashboard-investments-investments-offer')"
    >
      <span>{{ $t('layout.links.instruments-offer') }}</span>
    </DropdownItem>

    <DropdownItem component="link" level="2" :link="localePath('dashboard-investments-voting')">
      <span>{{ $t('layout.links.voting') }}</span>
    </DropdownItem>
  </DropdownItem>
</template>
