<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 14.505V21.5H4v-6.995m8-7.006c0-1.149-.13-4.997-3.41-4.997a2.43 2.43 0 0 0-2.36 1.51C5.8 5.09 6.35 6.81 8.51 7.489m4.56-3.927a2.91 2.91 0 0 1 2.34-1 2.43 2.43 0 0 1 2.36 1.45c.43 1.079-.12 2.798-2.28 3.477M3 7.51h18v4.997H3z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
</template>
