<script lang="ts">
type Alignment = 'left' | 'right';
type Props = {
  link?: string;
  title?: string;
  alignment: Alignment;
  useActiveClass?: boolean;
};
type Slots = {
  default(props: { opened: boolean }): unknown;
  badge(props: unknown): unknown;
  trigger(props: unknown): unknown;
};
</script>

<script setup lang="ts">
import IconChevronDown from '~/components/icons/ChevronDown.vue';

const props = withDefaults(defineProps<Props>(), {
  link: undefined,
  title: undefined,
  useActiveClass: true,
});
const slots = defineSlots<Slots>();

const isHovering = ref(false);
const hasContent = computed(() => !!slots.default);
const hasTrigger = computed(() => !!slots.trigger);
const component = computed(() => (props.link ? resolveComponent('NuxtLink') : 'div'));
const linkAttrs = computed(() => {
  if (props.link) {
    return {
      class: {
        'main-link': true,
      },
      to: props.link,
      ...(!props.useActiveClass
        ? {
            activeClass: 'not-active',
          }
        : {}),
    };
  }
  return {};
});

function onHover(hovering: boolean) {
  window.setTimeout(() => {
    isHovering.value = hovering;
  }, 0);
}
</script>

<template>
  <div
    class="dropdown"
    @mouseenter="onHover(true)"
    @pointerenter="onHover(true)"
    @mouseleave="onHover(false)"
  >
    <component v-bind="linkAttrs" :is="component" class="main-link">
      <slot v-if="hasTrigger" name="trigger" />

      <template v-else>{{ title }}</template>

      <slot name="badge" />

      <IconChevronDown v-if="hasContent && !hasTrigger" />
    </component>

    <div
      v-if="hasContent"
      class="dropdown__content"
      :class="{ [`dropdpown__content--alignment-${alignment}`]: true }"
    >
      <slot :opened="isHovering" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown {
  position: relative;

  margin-bottom: -1.25rem;
  padding-bottom: 1.25rem;

  &:hover .dropdown__content {
    display: flex;
  }
}

.dropdown__content {
  position: absolute;
  z-index: 9999;
  top: 100%;

  display: none;
  flex-direction: column;
  gap: 0.25rem;

  padding: 1rem;

  width: 19rem;
  max-height: calc(95vh - var(--menu-clearfix-height));

  border-radius: 0.375rem;
  background: var(--white);

  box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.1);
  overflow: auto;

  &.dropdpown__content--alignment-left {
    left: 0;
  }

  &.dropdpown__content--alignment-right {
    right: 0;
  }
}

.main-link {
  display: flex;
  align-items: center;

  padding: var(--menu-item-padding);

  height: 100%;

  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: var(--red-bright);
  }

  &.router-link-active {
    color: var(--white);
  }

  &:is(a) svg {
    margin-left: 0.25rem;
    transition: transform 0.2s ease-in-out;
    will-change: transform;
  }

  &:is(a):hover svg {
    transform: rotate(-180deg);
  }
}
</style>
