<script setup lang="ts">
const localePath = useLocalePath();
</script>

<template>
  <Button
    class="button--deposit-money"
    theme="dark"
    as="NuxtLink"
    :to="localePath('dashboard-finance-deposit')"
  >
    <span>{{ $t('global.deposit-money') }}</span>

    <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="8" r="7.5" stroke-width="1.5" stroke="currentColor" />

      <path stroke="currentColor" stroke-width="1.5" d="M11 1.5H5m3 4H0m11 4H6m5 5H3" />
    </svg>
  </Button>
</template>

<style lang="scss" scoped>
.button--deposit-money {
  margin: var(--menu-item-padding);
  padding: 0.5rem 1.25rem;

  height: 100%;

  span {
    display: none;
  }

  svg {
    display: block;
  }

  @media only screen and (min-width: 992px) {
    span {
      display: inline;
    }

    svg {
      display: none;
    }
  }
}
</style>
