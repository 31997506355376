<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2.5c-3.5 3-6.5 3-9.5 3v.26A17.8 17.8 0 0 0 12 21.5a17.8 17.8 0 0 0 9.5-15.74V5.5a13.79 13.79 0 0 1-9.5-3Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
</template>
