<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.25 12c0 6.213-5.037 11.25-11.25 11.25S.75 18.213.75 12 5.787.75 12 .75 23.25 5.787 23.25 12Z"
      fill="#93D3AB"
      stroke="#000"
      stroke-width="1.5"
    />
    <path
      d="m9.723 13.236-1.41 3.712m1.41-3.712 6.867-.796m-6.867.796L7.554 6M15 13l1.23 4.25M18 16.23c-3.65 2.69-8.71 2.31-12-1.1"
      stroke="#000"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>
