<script lang="ts">
type Section = 'accounts' | 'investments' | 'finance' | 'advertising-space' | '';
type Emits = {
  close: [];
};
</script>

<script setup lang="ts">
import Logo from '~/components/common/Logo.vue';
import DropdownItem from '~/components/layout/internal/shared/DropdownItem.vue';
import Badge from '~/components/common/Badge.vue';
import IconAccounts from '~/components/layout/internal/icons/Accounts.vue';
import IconInvestments from '~/components/layout/internal/icons/Investments.vue';
import IconAdvertisingSpace from '~/components/layout/internal/icons/AdvertisingSpace.vue';
import IconFinance from '~/components/layout/internal/icons/Finance.vue';
import IconChevronDown from '~/components/icons/ChevronDown.vue';
import IconProfile from '~/components/layout/internal/icons/Profile.vue';
import IconBank from '~/components/layout/internal/icons/Bank.vue';
import IconDocuments from '~/components/layout/internal/icons/Documents.vue';
import IconSecurity from '~/components/layout/internal/icons/Security.vue';
import IconPreferences from '~/components/layout/internal/icons/Preferences.vue';
import IconPresents from '~/components/layout/internal/icons/Presents.vue';
import Accounts from '~/components/layout/internal/shared/Accounts.vue';
import Investments from '~/components/layout/internal/mobile/Investments.vue';
import Finance from '~/components/layout/internal/mobile/Finance.vue';
import AdvertisingSpace from '~/components/layout/internal/mobile/AdvertisingSpace.vue';

const emit = defineEmits<Emits>();

const localePath = useLocalePath();
const router = useRouter();
const authStore = useAuthStore();
const advertisingStore = useAdvertisingStore();
const { user } = storeToRefs(authStore);
const { buyingTrades } = storeToRefs(advertisingStore);

const height = ref('100vh');
const expandedSection = ref<Section>('accounts');
const hasTradesToConfirm = computed(
  () => !!user.value.AgreedGallerySecTradeTerms && buyingTrades.value.length > 0,
);

function close() {
  emit('close');
}
function logout() {
  authStore.logout();

  router.push(localePath('/'));
}
function onResize() {
  height.value = `${window.innerHeight}px`;
}
function toggleSection(section: Exclude<Section, ''>) {
  if (expandedSection.value === section) {
    expandedSection.value = '';

    return;
  }

  expandedSection.value = section;
}

onMounted(() => {
  onResize();

  window.addEventListener('resize', onResize);
});
onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});
</script>

<template>
  <div class="overlay overlay--active" :style="{ '--height': height }">
    <div class="menu light">
      <DropdownItem component="text" class="header">
        <Logo alt=" " theme="light" />

        <Button
          class="button--icon"
          variant="text"
          theme="light"
          :title="$t('layout.external.navigation.hamburger.close')"
          @click="close"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              d="m4 4 16 16m0-16L4 20"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-miterlimit="10"
            />
          </svg>
        </Button>
      </DropdownItem>

      <div class="content">
        <DropdownItem component="button" @click="toggleSection('accounts')">
          <template #icon>
            <IconAccounts />
          </template>

          <div class="collapsible__title">
            <span>{{ $t('layout.internal.navigation.accounts.title') }}</span>

            <IconChevronDown
              class="icon--chevron"
              :class="{ 'icon--rotated': expandedSection === 'accounts' }"
            />
          </div>
        </DropdownItem>

        <Accounts show-section :expanded="expandedSection === 'accounts'" />

        <DropdownItem component="button" @click="toggleSection('investments')">
          <template #icon>
            <IconInvestments />
          </template>

          <div class="collapsible__title">
            <span>{{ $t('layout.links.investments') }}</span>

            <IconChevronDown
              class="icon--chevron"
              :class="{ 'icon--rotated': expandedSection === 'investments' }"
            />
          </div>
        </DropdownItem>

        <Investments show-section :expanded="expandedSection === 'investments'" />

        <DropdownItem component="button" @click="toggleSection('advertising-space')">
          <template #icon>
            <IconAdvertisingSpace />
          </template>

          <div class="collapsible__title">
            <span>{{ $t('layout.links.advertisement') }}</span>

            <Badge v-if="hasTradesToConfirm" color="red">{{ buyingTrades.length }}</Badge>

            <IconChevronDown
              class="icon--chevron"
              :class="{ 'icon--rotated': expandedSection === 'advertising-space' }"
            />
          </div>
        </DropdownItem>

        <AdvertisingSpace show-section :expanded="expandedSection === 'advertising-space'" />

        <DropdownItem component="button" @click="toggleSection('finance')">
          <template #icon>
            <IconFinance />
          </template>

          <div class="collapsible__title">
            <span>{{ $t('layout.links.transactions-and-money') }}</span>

            <IconChevronDown
              class="icon--chevron"
              :class="{ 'icon--rotated': expandedSection === 'finance' }"
            />
          </div>
        </DropdownItem>

        <Finance show-section :expanded="expandedSection === 'finance'" />

        <DropdownItem component="link" :link="localePath('dashboard-profile')">
          <template #icon>
            <IconProfile />
          </template>

          <span>{{ $t('layout.links.profile') }}</span>
        </DropdownItem>

        <DropdownItem component="link" :link="localePath('dashboard-profile-bank-accounts')">
          <template #icon>
            <IconBank />
          </template>

          <span>{{ $t('layout.links.bank-accounts') }}</span>
        </DropdownItem>

        <DropdownItem
          component="link"
          :link="localePath('dashboard-profile-statements-and-documents')"
        >
          <template #icon>
            <IconDocuments />
          </template>

          <span>{{ $t('layout.links.statements-and-documents') }}</span>
        </DropdownItem>

        <DropdownItem component="link" :link="localePath('dashboard-profile-security')">
          <template #icon>
            <IconSecurity />
          </template>

          <span>{{ $t('layout.links.security') }}</span>
        </DropdownItem>

        <DropdownItem component="link" :link="localePath('dashboard-profile-email-preferences')">
          <template #icon>
            <IconPreferences />
          </template>

          <span>{{ $t('layout.links.email-preferences') }}</span>
        </DropdownItem>

        <DropdownItem component="link" :link="localePath('dashboard-gifts-and-benefits-vouchers')">
          <template #icon>
            <IconPresents />
          </template>

          <span>{{ $t('layout.links.vouchers') }}</span>
        </DropdownItem>
      </div>

      <Button class="button--logout" variant="outlined" theme="light" @click="logout">{{
        $t('layout.internal.navigation.profile.log-out')
      }}</Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.overlay {
  --container-padding: 5vmin;

  z-index: 100001;
  position: absolute;
  top: 0;
  right: calc(-1 * var(--scrollbar-width));
  bottom: 0;
  left: 0;

  height: var(--height);

  background-color: rgba(48, 33, 25, 0.8); //dark-brown
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  margin-left: auto;
  padding: 1rem;

  width: 100%;
  height: 100%;

  background-color: var(--white);

  @media only screen and (min-width: 576px) {
    max-width: calc(22rem + var(--container-padding));
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.375rem;

  overflow: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapsible__title {
  display: flex;
  align-items: center;
}

.accounts.dropdown__item.dropdown__item--section.dropdown__item--collapsible:not(
    .dropdown__item--expanded
  ) {
  margin-bottom: -5px;
}

.badge {
  --border-color: var(--red-basic);
  --background-color: var(--red-basic);

  margin-left: 0.25rem;

  color: var(--white);
}
</style>
