<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.25 12c0 6.213-5.037 11.25-11.25 11.25S.75 18.213.75 12 5.787.75 12 .75 23.25 5.787 23.25 12Z"
      fill="#14C850"
      stroke="#000"
      stroke-width="1.5"
    />
    <path
      d="M5.46 16.18c0 .453.368.82.822.82h5.25a.672.672 0 1 0 0-1.344H7.57l4.398-5.733a.875.875 0 0 0-.694-1.407H6.16a.668.668 0 1 0 0 1.336h3.988l-4.515 5.825a.82.82 0 0 0-.172.502m9.594-.031a.852.852 0 1 0 1.703 0V6.04a.852.852 0 1 0-1.703 0zm2.749-5.515a.594.594 0 1 0-.53-1.063l-3.283 1.642a.591.591 0 0 0 .528 1.057z"
      fill="#000"
    />
  </svg>
</template>
