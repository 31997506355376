<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m12.003 11.389 8 4.611-8 4.611-8-4.611zm0-.389-8.667 5 8.667 5 8.666-5z"
      fill="#9646AA"
    />
    <path d="m20.614 8.004-8.612 5-8.666-5 8.667-5z" fill="#9646AA" />
    <path d="M12.003 13.008v4l-8.667-5v-4z" fill="#640D79" />
    <path d="M12.008 13.008v4l8.61-5v-4z" fill="#721689" />
  </svg>
</template>
