<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.25 12c0 6.213-5.037 11.25-11.25 11.25S.75 18.213.75 12 5.787.75 12 .75 23.25 5.787 23.25 12Z"
      fill="#fff"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M5.758 20a3.73 3.73 0 0 1 3.436-2.274h5.596A3.73 3.73 0 0 1 18.225 20m-2.503-9.27a3.73 3.73 0 1 1-7.461 0 3.73 3.73 0 0 1 7.461 0"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
