<script setup lang="ts">
import Overlay from '~/components/layout/internal/mobile/Overlay.vue';

const route = useRoute();
const layoutStore = useLayoutStore();

const opened = ref(false);

function close() {
  opened.value = !opened.value;

  layoutStore.setDefaultOpenedMenuSection();
}

watch(
  () => route.path,
  () => {
    if (!opened.value) {
      return;
    }

    close();
  },
);
</script>

<template>
  <Button
    class="button--trigger"
    variant="text"
    theme="dark"
    :title="$t(`layout.external.navigation.hamburger.${opened ? 'close' : 'open'}`)"
    @click="close"
  >
    <svg
      v-if="!opened"
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path d="M2 5.5h20m-20 7h20m-20 7h20" stroke="currentColor" stroke-width="1.5" />
    </svg>

    <svg
      v-else
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="m4 4 16 16m0-16L4 20"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </svg>
  </Button>

  <Overlay v-if="opened" @close="close" />
</template>

<style lang="scss" scoped>
.button--trigger {
  padding: var(--menu-item-padding);

  height: 100%;

  --color: var(--white);

  &:hover {
    --color: var(--red-basic);
  }
}
</style>
