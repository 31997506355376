<script setup lang="ts">
import Dropdown from '~/components/layout/internal/shared/Dropdown.vue';
import DropdownItem from '~/components/layout/internal/shared/DropdownItem.vue';
import Balance from '~/components/layout/internal/shared/Balance.vue';

const localePath = useLocalePath();
</script>

<template>
  <Dropdown
    key="finance"
    alignment="left"
    :title="$t('layout.links.transactions-and-money')"
    :link="localePath('dashboard-finance')"
  >
    <Balance level="1" />

    <DropdownItem component="divider" />

    <DropdownItem component="link" :link="localePath('dashboard-finance-deposit')">
      <span>
        {{ $t('layout.links.deposit') }}
      </span>
    </DropdownItem>

    <DropdownItem component="link" :link="localePath('dashboard-finance-withdrawal')">
      <span>
        {{ $t('layout.links.withdraw') }}
      </span>
    </DropdownItem>

    <DropdownItem component="link" :link="localePath('dashboard-finance-transactions-history')">
      <span>
        {{ $t('layout.links.wallet-and-transactions') }}
      </span>
    </DropdownItem>

    <DropdownItem component="link" :link="localePath('dashboard-finance-purchase-instructions')">
      <span>
        {{ $t('layout.links.purchase-instructions') }}
      </span>
    </DropdownItem>
  </Dropdown>
</template>
