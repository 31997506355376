<script lang="ts">
import { type VotingNotification, type Notification } from '~/models/notifications';

type PermanentNotifications = Readonly<{
  [key: string]: (notification: Notification) => {
    title: string;
    content: string;
    button?: string;
    buttonDisabled?: boolean;
    buttonSuccess?: boolean;
    link?: string;
    linkText?: string;
    action?: () => void;
    attributes?: any;
  };
}>;
type InfoNotifications = Readonly<{
  [key: string]: (notification: Notification) => {
    content: string;
    attributes?: any;
  } & ({ link?: undefined } | { link: string; linkText: string });
}>;
</script>

<script setup lang="ts">
const {
  public: { cdnUrl },
} = useRuntimeConfig();
const { d } = useI18n();
const localePath = useLocalePath();
const instance = getCurrentInstance();
const authStore = useAuthStore();
const notificationsStore = useNotificationsStore();
const {
  infoNotifications,
  infoNotificationsCount,
  pauseNotifications,
  permanentNotifications,
  showInfoNotifications,
} = storeToRefs(notificationsStore);

const permanentNotificationSettings: PermanentNotifications = {
  IN_PROGRESS: () => ({
    title: 'layout.internal.notifications.permanent.unfinished-registration.title',
    content: 'layout.internal.notifications.permanent.unfinished-registration.caption',
    button: 'layout.internal.notifications.permanent.unfinished-registration.button',
    link: localePath('account-personal-data'),
  }),
  EMAIL_NOT_VERIFIED: () => ({
    title: 'layout.internal.notifications.permanent.unverified-email.title',
    content: 'layout.internal.notifications.permanent.unverified-email.caption',
    button: 'layout.internal.notifications.permanent.unverified-email.button',
    action: async () => {
      permanentNotification.value!.buttonDisabled = true;

      instance?.proxy?.$forceUpdate();

      try {
        await authStore.resendVerificationEmail();

        permanentNotification.value!.buttonSuccess = true;
      } catch (error) {
        permanentNotification.value!.buttonDisabled = false;
      }

      instance?.proxy?.$forceUpdate();
    },
  }),
  CRN_TAX_RES_CONFIRMATION_REQUIRED: () => ({
    title: 'layout.internal.notifications.permanent.unfinished-registration.title',
    content: 'layout.internal.notifications.permanent.confirm-data.caption',
  }),
  CRN: () => ({
    title: 'layout.internal.notifications.permanent.info-and-documents-needed.title',
    content: 'layout.internal.notifications.permanent.info-and-documents-needed.caption',
    button: 'layout.internal.notifications.permanent.info-and-documents-needed.button',
    link: localePath('account-document-update'),
  }),
  CRN_IDENTIFICATION: () => ({
    title: 'layout.internal.notifications.permanent.unverified-identity.title',
    content: 'layout.internal.notifications.permanent.unverified-identity.caption',
    button: 'layout.internal.notifications.permanent.unverified-identity.button',
    link: localePath('account-document-update'),
  }),
  CRN_INFO_AND_DOCUMENTS: () => ({
    title: 'layout.internal.notifications.permanent.info-and-documents-needed.title',
    content: 'layout.internal.notifications.permanent.info-and-documents-needed.caption',
    button: 'layout.internal.notifications.permanent.info-and-documents-needed.button',
    link: localePath('account-document-update'),
  }),
  CRN_INFO_ONLY: () => ({
    title: 'layout.internal.notifications.permanent.info-needed.title',
    content: 'layout.internal.notifications.permanent.info-needed.caption',
    link: localePath('account-document-update'),
  }),
  // @ts-ignore
  VOTE: (notification: VotingNotification) => ({
    title: 'layout.internal.notifications.permanent.voting.title',
    content: 'layout.internal.notifications.permanent.voting.caption',
    button: 'layout.internal.notifications.permanent.voting.button',
    link: localePath({
      name: 'dashboard-investments-voting',
      query: {
        id: notification.Vote.Survey.Id,
      },
    }),
    attributes: {
      instrumentName: notification.Vote.Survey.Instrument.Name,
      validTo: d(new Date(notification.Vote.Survey.ValidTo), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
    },
  }),
  IS_LOCKED: () => ({
    title: 'layout.internal.notifications.permanent.something-broken.title',
    content: 'layout.internal.notifications.permanent.something-broken.caption',
    button: 'layout.internal.notifications.permanent.something-broken.button',
    link: localePath('contact'),
  }),
  ALTERNATIVE_USERIDENTIFICATION_REQUIRED: () => ({
    title: 'layout.internal.notifications.permanent.bank-id.title',
    content: 'layout.internal.notifications.permanent.bank-id.caption',
    button: 'layout.internal.notifications.permanent.bank-id.button',
    link: localePath('dashboard-profile-identity-verification'),
  }),
  MISSING_SOURCE_OF_INCOME_STATEMENT: () => ({
    title: 'layout.internal.notifications.permanent.missing-pep-source-of-income-statement.title',
    content:
      'layout.internal.notifications.permanent.missing-pep-source-of-income-statement.caption',
    button: 'layout.internal.notifications.permanent.missing-pep-source-of-income-statement.button',
    link: localePath({
      name: 'account-politically-exposed-person',
      query: {
        'file-upload': true.toString(),
      },
    }),
  }),
  TBV_CT_UNKNOWN_BANK_ACCOUNT: () => ({
    title: 'layout.internal.notifications.permanent.unknown-bank-account.title',
    content: 'layout.internal.notifications.permanent.unknown-bank-account.caption',
    button: 'layout.internal.notifications.permanent.unknown-bank-account.button',
    link: localePath('dashboard-profile-bank-accounts'),
  }),
  TBV_CT: () => ({
    title: 'layout.internal.notifications.permanent.deposit-not-attributed.title',
    content: 'layout.internal.notifications.permanent.deposit-not-attributed.caption',
    button: 'layout.internal.notifications.permanent.deposit-not-attributed.button',
    link: localePath('contact'),
  }),
  QUESTIONNAIRE_EXPIRED: () => ({
    title: 'layout.internal.notifications.permanent.expired-questionnaire.title',
    content: 'layout.internal.notifications.permanent.expired-questionnaire.caption',
    button: 'layout.internal.notifications.permanent.expired-questionnaire.button',
    link: localePath('account-questionnaire-update'),
  }),
  PERSONAL_DATA_CONFIRMATION: () => ({
    title: 'layout.internal.notifications.permanent.personal-data-confirmation.title',
    content: 'layout.internal.notifications.permanent.personal-data-confirmation.caption',
    button: 'layout.internal.notifications.permanent.bank-id.button',
    link: localePath('dashboard-profile-personal-data-confirmation'),
  }),
  FINISH_PERSONAL_DATA_CONFIRMATION_PEP: () => ({
    title: 'layout.internal.notifications.permanent.personal-data-confirmation-soi.title',
    content: 'layout.internal.notifications.permanent.personal-data-confirmation.caption',
    button: 'layout.internal.notifications.permanent.bank-id.button',
    link: localePath('dashboard-profile-personal-data-confirmation-politically-exposed-person'),
  }),
  FINISH_PERSONAL_DATA_CONFIRMATION_SOI: () => ({
    title: 'layout.internal.notifications.permanent.personal-data-confirmation-soi.title',
    content: 'layout.internal.notifications.permanent.personal-data-confirmation.caption',
    button: 'layout.internal.notifications.permanent.bank-id.button',
    link: localePath('dashboard-profile-personal-data-confirmation-source-of-income-statement'),
  }),
  ACCOUNT_IS_NOT_ACTIVE: () => ({
    title: 'layout.internal.notifications.permanent.account-not-active.title',
    content: 'layout.internal.notifications.permanent.account-not-active.content',
    button: 'layout.internal.notifications.permanent.account-not-active.button',
    link: localePath('dashboard-finance-deposit'),
  }),
};
// @ts-ignore
const infoNotificationSettings: InfoNotifications = {
  BANKID_AVAILABLE: (notification: Notification) => ({
    content: 'layout.internal.notifications.info.bank-id-available.caption',
    link: notification.Link,
    linkText: 'layout.internal.notifications.info.bank-id-available.link',
  }),
  TAX_REPORT_AVAILABLE: () => ({
    content: 'layout.internal.notifications.info.tax-statement.caption',
    link: localePath('dashboard-profile-statements-and-documents-statements-and-confirmations'),
    linkText: 'layout.internal.notifications.info.tax-statement.link',
    attributes: {
      year: new Date().getFullYear() - 1,
    },
  }),
  CLIENT_COMPLETED: () => ({
    content: 'layout.internal.notifications.info.identity-verification',
  }),
  QUESTIONNAIRE_AGAIN_ENABLED: () => ({
    content: 'layout.internal.notifications.info.retake-questionnaire',
    link: localePath('account-questionnaire-again'),
    linkText: 'global.here',
  }),
  QUESTIONNAIRE_AGAIN_CONTINUE: () => ({
    content: 'layout.internal.notifications.info.continue-retaking-questionnaire',
    link: localePath('account-questionnaire-again'),
    linkText: 'global.here',
  }),
  // @ts-ignore
  SURVEY_RESULTS: (notification: VotingNotification) => ({
    content: 'layout.internal.notifications.info.survey-results',
    link: localePath({
      name: 'dashboard-investments-voting',
      query: {
        id: notification.Vote.Survey.Id,
      },
    }),
    linkText: 'global.here',
    attributes: {
      instrumentName: notification.Vote.Survey.Instrument.Name,
    },
  }),
  IDENTIFICATION_EXPIRED: () => ({
    content: 'layout.internal.notifications.info.expired-documents',
    link: localePath('dashboard-profile-document-update'),
    linkText: 'global.here',
  }),
  PRIMARY_IDENTIFICATION_EXPIRED: () => ({
    content: 'layout.internal.notifications.info.expired-identity-card',
    link: localePath('dashboard-profile-document-update'),
    linkText: 'global.here',
  }),
  DOCUMENT_VERIFICATION_IN_PROGRESS: () => ({
    content: 'layout.internal.notifications.info.document-being-verified',
  }),
  GALLERY_ELEMENT: () => ({
    content: 'layout.internal.notifications.info.2023-element.content',
    link: `${cdnUrl}/documents/ELEMENT%202023.pdf`,
    linkText: 'layout.internal.notifications.info.2023-element.link-text',
  }),
} as const;

const activeNotificationIndex = ref(0);
const permanentNotification = computed(() => {
  if (permanentNotifications.value.length === 0) {
    return null;
  }

  const notification = permanentNotifications.value[0];
  const notificationSettings = permanentNotificationSettings[notification.Code];

  return {
    ...notification,
    ...notificationSettings(notification),
  };
});
const activeNotification = computed(() => {
  const notification = infoNotifications.value[activeNotificationIndex.value];
  const notificationSettings = infoNotificationSettings[notification.Code];

  return {
    ...notification,
    ...notificationSettings(notification),
  };
});
const isShowingFirstNotification = computed(() => activeNotificationIndex.value === 0);
const isShowingLastNotification = computed(
  () => activeNotificationIndex.value === infoNotificationsCount.value - 1,
);

const showNextNotification = () => {
  activeNotificationIndex.value = activeNotificationIndex.value + 1;
};
const showPreviousNotification = () => {
  activeNotificationIndex.value = activeNotificationIndex.value - 1;
};
const closeNotification = () => {
  notificationsStore.setClosedInfoNotification({ notification: activeNotification.value });
  notificationsStore.closeInfoNotification({ index: activeNotificationIndex.value });

  if (isShowingLastNotification.value && isShowingFirstNotification.value) {
    activeNotificationIndex.value = 0;

    return;
  }

  if (activeNotificationIndex.value > infoNotifications.value.length - 1) {
    showPreviousNotification();
  }
};

const notificationAttributes = (notification: { attributes?: any }) => {
  const attributes = notification?.attributes;
  if (attributes) {
    return Object.keys(attributes).map((attributeKey) => ({
      value: attributes[attributeKey],
      name: attributeKey,
    }));
  }

  return [];
};

onMounted(() => {
  notificationsStore.getNotifications({});
});
</script>

<template>
  <div class="notifications">
    <div v-if="permanentNotification && !pauseNotifications" class="permanent-notifications">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="notification">
              <h6 class="notification__header">{{ $t(permanentNotification.title) }}</h6>
            </div>

            <div class="notification__content">
              <i18n-t tag="span" scope="global" :keypath="permanentNotification.content">
                <template
                  v-for="attribute in notificationAttributes(permanentNotification)"
                  #[attribute.name]
                  :key="attribute.name"
                >
                  <span>{{ attribute.value }}</span>
                </template>
              </i18n-t>
            </div>

            <div v-if="permanentNotification.button" class="notification__footer">
              <Button
                v-if="permanentNotification.link"
                theme="light"
                as="NuxtLink"
                :to="permanentNotification.link"
              >
                {{ $t(permanentNotification.button) }}
              </Button>

              <Button
                v-if="permanentNotification.action"
                theme="light"
                :disabled="permanentNotification.buttonDisabled"
                :loading="permanentNotification.buttonDisabled"
                :success="permanentNotification.buttonSuccess"
                @click="permanentNotification.action"
              >
                {{ $t(permanentNotification.button) }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showInfoNotifications"
      class="temporary-notifications"
      :class="{ 'temporary-notifications--single': infoNotificationsCount === 1 }"
    >
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="notification">
              <div v-if="infoNotificationsCount > 1" class="notification__pagination">
                <button
                  type="button"
                  :disabled="isShowingFirstNotification"
                  @click="showPreviousNotification"
                >
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="currentColor"
                  >
                    <title>{{ $t('layout.internal.notifications.navigation.previous') }}</title>

                    <path d="M6 1L1 6L6 11" stroke-miterlimit="10" />
                  </svg>
                </button>

                <div class="notifications__count">
                  <span>{{ activeNotificationIndex + 1 }} / {{ infoNotificationsCount }}</span>
                </div>

                <button
                  type="button"
                  :disabled="isShowingLastNotification"
                  @click="showNextNotification"
                >
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="currentColor"
                  >
                    <title>{{ $t('layout.internal.notifications.navigation.next') }}</title>

                    <path d="M1 11L6 6L1 0.999998" stroke-miterlimit="10" />
                  </svg>
                </button>
              </div>

              <div class="notification__content">
                <i18n-t tag="span" scope="global" :keypath="activeNotification.content">
                  <template
                    v-if="activeNotification.link && activeNotification.link.length > 0"
                    #link
                  >
                    <a
                      v-if="activeNotification.link.startsWith('http')"
                      target="_blank"
                      :href="activeNotification.link"
                    >
                      {{ $t(activeNotification.linkText) }}
                    </a>

                    <NuxtLink v-else :to="activeNotification.link">{{
                      $t(activeNotification.linkText)
                    }}</NuxtLink>
                  </template>

                  <template
                    v-for="attribute in notificationAttributes(activeNotification)"
                    #[attribute.name]
                    :key="attribute.name"
                  >
                    <span>{{ attribute.value }}</span>
                  </template>
                </i18n-t>
              </div>

              <div class="notification__close">
                <button type="button" @click="closeNotification">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="currentColor"
                  >
                    <title>{{ $t('layout.internal.notifications.navigation.close') }}</title>

                    <path d="M1 1L17 17" stroke-miterlimit="10" />
                    <path d="M17 1L1 17" stroke-miterlimit="10" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notifications {
  %notifications-container {
    padding: 1.5rem 0;

    h6 {
      margin: 0;
    }

    h6,
    span,
    a {
      color: var(--color);

      &::selection {
        background-color: var(--white);
        color: var(--blue-dark);
      }
    }

    .notification__header {
      margin-bottom: 0.5rem;
    }

    .notification__footer {
      margin-top: 1rem;
    }
  }

  .permanent-notifications {
    @extend %notifications-container;

    --color: var(--black);

    background-color: var(--yellow-basic);
  }

  .temporary-notifications {
    @extend %notifications-container;

    --color: var(--white);

    background-color: var(--blue-dark);

    button {
      color: var(--color);
    }

    button:disabled {
      color: var(--gray-light);
    }

    a {
      color: var(--white);
    }

    .notification {
      display: grid;
      grid-template-areas:
        'pagination close'
        'content content';
      align-items: start;
      grid-gap: 1.5rem;

      @media (min-width: 768px) {
        grid-template-areas: 'pagination content close';
        grid-template-columns: auto 1fr auto;
      }
    }

    &.temporary-notifications--single {
      .notification {
        @media (min-width: 768px) {
          grid-template-areas: 'content close';
          grid-template-columns: 1fr auto;
        }
      }
    }

    .notification__pagination {
      grid-area: pagination;

      display: flex;
      align-items: center;
    }

    .notification__close {
      grid-area: close;

      display: flex;
      justify-content: flex-end;

      height: calc(1.5 * var(--font-size-base));
    }

    .notification__content {
      grid-area: content;
    }

    .notifications__count {
      text-align: center;
      font-variant-numeric: tabular-nums;
    }
  }

  .notification {
    .notification__pagination,
    .notification__close {
      button {
        display: flex;
        align-items: center;

        border-width: 0;
        background-color: transparent;
      }
    }

    .notification__count {
      display: flex;
      justify-content: center;
      width: 1.5rem;
    }

    .notification__pagination {
      button {
        &:first-of-type {
          margin-right: 0.5rem;
        }

        &:last-of-type {
          margin-left: 0.5rem;
        }
      }
    }
  }
}
</style>
