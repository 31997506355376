<script lang="ts">
type Props = {
  showSection: boolean;
  expanded: boolean;
};
</script>

<script setup lang="ts">
import DropdownItem from '~/components/layout/internal/shared/DropdownItem.vue';
import Balance from '~/components/layout/internal/shared/Balance.vue';

defineProps<Props>();

const localePath = useLocalePath();
</script>

<template>
  <DropdownItem
    v-if="showSection"
    collapsible
    component="section"
    class="finance"
    :expanded="expanded"
  >
    <Balance level="2" />

    <DropdownItem component="divider" level="2" />

    <DropdownItem component="link" level="2" :link="localePath('dashboard-finance-deposit')">
      <span>{{ $t('layout.links.deposit') }}</span>
    </DropdownItem>

    <DropdownItem component="link" level="2" :link="localePath('dashboard-finance-withdrawal')">
      <span>{{ $t('layout.links.withdraw') }}</span>
    </DropdownItem>

    <DropdownItem
      component="link"
      level="2"
      :link="localePath('dashboard-finance-transactions-history')"
    >
      <span>{{ $t('layout.links.wallet-and-transactions') }}</span>
    </DropdownItem>

    <DropdownItem
      component="link"
      level="2"
      :link="localePath('dashboard-finance-purchase-instructions')"
    >
      <span>{{ $t('layout.links.purchase-instructions') }}</span>
    </DropdownItem>
  </DropdownItem>
</template>
