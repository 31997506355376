<script setup lang="ts">
import Logo from '~/components/common/Logo.vue';
import Spacer from '~/components/common/Spacer.vue';
import DashboardLink from '~/components/layout/internal/desktop/DashboardLink.vue';
import Investments from '~/components/layout/internal/desktop/Investments.vue';
import Finance from '~/components/layout/internal/desktop/Finance.vue';
import AdvertisingSpace from '~/components/layout/internal/desktop/AdvertisingSpace.vue';
import Other from '~/components/layout/internal/desktop/Other.vue';
import DepositMoney from '~/components/layout/internal/shared/DepositMoney.vue';

const localePath = useLocalePath();
const portfoliosStore = usePortfoliosStore();
const authStore = useAuthStore();
const { isLoggedIn } = storeToRefs(authStore);

onMounted(() => {
  portfoliosStore.getPortfolios({});
});
</script>

<template>
  <div class="container">
    <div class="logo">
      <NuxtLink :to="localePath('dashboard')">
        <Logo :alt="$t('layout.external.navigation.logo-alt')" />
      </NuxtLink>
    </div>

    <Spacer />

    <div class="items">
      <DashboardLink />

      <Investments />

      <AdvertisingSpace />

      <Finance />

      <DepositMoney />

      <template v-if="isLoggedIn">
        <Other />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  position: relative;

  display: none;
  align-items: stretch;

  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  .logo:not(img) {
    display: inline-flex;
    align-items: center;

    width: 7.5rem;

    a {
      display: inherit;
    }
  }

  @media only screen and (min-width: 1200px) {
    display: flex;
  }
}

.items {
  --menu-item-padding: 0 0.5rem;

  display: flex;
  flex-direction: row;

  @media only screen and (min-width: 992px) {
    --menu-item-padding: 0 1rem;
  }
}
</style>
