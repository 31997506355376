<script setup lang="ts">
import Dropdown from '~/components/layout/internal/shared/Dropdown.vue';
import DropdownItem from '~/components/layout/internal/shared/DropdownItem.vue';

const localePath = useLocalePath();
</script>

<template>
  <Dropdown
    key="investments"
    alignment="left"
    :title="$t('layout.links.investments')"
    :link="localePath('dashboard-investments')"
  >
    <DropdownItem component="link" :link="localePath('dashboard-investments-my-investments')">
      <span>
        {{ $t('layout.links.my-investments') }}
      </span>
    </DropdownItem>

    <DropdownItem component="link" :link="localePath('dashboard-investments-investments-offer')">
      <span>
        {{ $t('layout.links.instruments-offer') }}
      </span>
    </DropdownItem>

    <DropdownItem component="link" :link="localePath('dashboard-investments-voting')">
      <span>
        {{ $t('layout.links.voting') }}
      </span>
    </DropdownItem>
  </Dropdown>
</template>
