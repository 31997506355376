<script setup lang="ts">
import Dropdown from '~/components/layout/internal/shared/Dropdown.vue';
import DropdownItem from '~/components/layout/internal/shared/DropdownItem.vue';
import Badge from '~/components/common/Badge.vue';

const localePath = useLocalePath();
const authStore = useAuthStore();
const advertisingStore = useAdvertisingStore();
const { user } = storeToRefs(authStore);
const { buyingTrades } = storeToRefs(advertisingStore);

const hasTradesToConfirm = computed(
  () => !!user.value.AgreedGallerySecTradeTerms && buyingTrades.value.length > 0,
);
onMounted(() => {
  if (!user.value.AgreedGallerySecTradeTerms) {
    return;
  }

  advertisingStore.getTrades({});
});
</script>

<template>
  <Dropdown
    key="advertisign-space"
    alignment="left"
    :title="$t('layout.links.advertisement')"
    :link="localePath('dashboard-advertising-space')"
  >
    <template v-if="hasTradesToConfirm" #badge>
      <Badge color="red">{{ buyingTrades.length }}</Badge>
    </template>

    <DropdownItem component="link" :link="localePath('dashboard-advertising-space')">
      <span>
        {{ $t('layout.links.advertising-space') }}
      </span>
    </DropdownItem>

    <DropdownItem
      component="link"
      :link="localePath('dashboard-advertising-space-my-advertisements')"
    >
      <span>
        {{ $t('layout.links.my-advertisements') }}
      </span>
    </DropdownItem>

    <DropdownItem
      component="link"
      :link="localePath('dashboard-advertising-space-advertisement-new')"
    >
      <span>
        {{ $t('layout.links.add-advertisement') }}
      </span>
    </DropdownItem>

    <DropdownItem component="link" :link="localePath('dashboard-advertising-space-trades')">
      <span>
        {{ $t('layout.links.advertising-space-trades') }}
      </span>

      <Badge v-if="hasTradesToConfirm" color="red">{{ buyingTrades.length }}</Badge>
    </DropdownItem>
  </Dropdown>
</template>

<style lang="scss" scoped>
.dropdown__item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .badge {
    margin: 0;
  }
}

.badge {
  --border-color: var(--red-basic);
  --background-color: var(--red-basic);

  margin-left: 0.25rem;

  color: var(--white);
}
</style>
