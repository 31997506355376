<script setup lang="ts">
import DesktopMenu from '~/components/layout/internal/DesktopMenu.vue';
import MobileMenu from '~/components/layout/internal/MobileMenu.vue';

const headerConrainerRef = ref<HTMLDivElement | null>(null);

function onResize() {
  document.body.setAttribute(
    'style',
    `--menu-clearfix-height: ${headerConrainerRef.value!.clientHeight}px;`,
  );
}

onMounted(() => {
  window.addEventListener('resize', onResize);
  window.addEventListener('scroll', onResize);

  onResize();
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
  window.removeEventListener('scroll', onResize);
});
</script>

<template>
  <nav class="header">
    <div ref="headerConrainerRef" class="header__container">
      <DesktopMenu />

      <MobileMenu />
    </div>

    <div class="header__clearfix"></div>
  </nav>
</template>

<style lang="scss" scoped>
.header__container {
  z-index: 9999;

  position: fixed;
  top: 0;

  width: 100%;

  background-color: var(--brown-darkened);
}

.header__clearfix {
  margin-top: var(--menu-clearfix-height, 3.75rem);
}
</style>
