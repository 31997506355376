<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12"
      fill="#14C850"
    />
    <path
      d="M23.25 12c0 6.213-5.037 11.25-11.25 11.25S.75 18.213.75 12 5.787.75 12 .75 23.25 5.787 23.25 12Z"
      stroke="#000"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M17 11.762c-.395-.485-.935-.76-1.5-.76s-1.09.275-1.5.76m-4 0c-.395-.485-.935-.76-1.5-.76s-1.09.275-1.5.76"
      stroke="#000"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 .75a3 3 0 0 0-3 3 3.01 3.01 0 0 0 3 3m4 9.656a5.63 5.63 0 0 1-8 0"
      stroke="#000"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>
