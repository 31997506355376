<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.25 12c0 6.213-5.037 11.25-11.25 11.25S.75 18.213.75 12 5.787.75 12 .75 23.25 5.787 23.25 12Z"
      fill="#14C850"
      stroke="#000"
      stroke-width="1.5"
    />
    <path
      d="m6.302 14.002 1.277-1.424.756-.669L11.06 8.9a.625.625 0 1 0-.93-.835l-3.6 4.05h-.298zM5 16.157a.711.711 0 0 0 1.423 0v-7.59a.711.711 0 1 0-1.423 0zm5.314.424a.709.709 0 1 0 1.136-.847l-3.102-4.118-1.004.95zM16.04 17q.857 0 1.467-.281.617-.288.985-.787t.489-1.162a.046.046 0 0 0-.045-.056l-1.23.006a.05.05 0 0 0-.046.031q-.165.55-.572.831-.4.282-1.048.281a1.67 1.67 0 0 1-.978-.287 1.86 1.86 0 0 1-.635-.83q-.223-.539-.223-1.287v-.013q0-.73.216-1.262.222-.53.635-.812a1.7 1.7 0 0 1 .985-.287q.686 0 1.099.337a1.46 1.46 0 0 1 .528.833.02.02 0 0 0 .017.01h1.284a.026.026 0 0 0 .026-.03 2.6 2.6 0 0 0-.47-1.169 2.5 2.5 0 0 0-.998-.812q-.622-.3-1.492-.3-1.005 0-1.734.425a2.85 2.85 0 0 0-1.119 1.206q-.387.78-.387 1.861v.013q0 1.098.394 1.892.393.793 1.118 1.224.73.425 1.734.425m1.713-9.156a.53.53 0 1 0-.861-.619l-.814 1.155h-.102l-.813-1.155a.53.53 0 1 0-.861.619l.657.896a1 1 0 0 0 .806.409h.524a1 1 0 0 0 .806-.41z"
      fill="#000"
    />
  </svg>
</template>
