<script setup lang="ts">
import Dropdown from '~/components/layout/internal/shared/Dropdown.vue';

const localePath = useLocalePath();
const route = useRoute();

const isExact = computed(
  () => typeof route.name === 'string' && route.name?.includes('dashboard___'),
);
</script>

<template>
  <Dropdown
    key="dashboard"
    alignment="left"
    :title="$t('layout.links.dashboard')"
    :link="localePath('dashboard')"
    :use-active-class="isExact"
  />
</template>
