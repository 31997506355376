<script lang="ts">
type Component = 'link' | 'button' | 'text' | 'divider' | 'section';
type Level = '1' | '2' | '3';
type Props = {
  component: Component;
  link?: string;
  level?: Level;
  active?: boolean;
  collapsible?: boolean;
  expanded?: boolean;
  notActivable?: boolean;
};
type Emits = {
  click: [event: MouseEvent];
};
type Slots = {
  default(props: unknown): unknown;
  icon(props: unknown): unknown;
};
</script>

<script setup lang="ts">
const props = withDefaults(defineProps<Props>(), {
  level: '1',
  link: undefined,
  active: false,
  collapsible: false,
  expanded: false,
  notActivable: false,
});
const emit = defineEmits<Emits>();
const slots = defineSlots<Slots>();

const itemRef = ref<HTMLElement | null>();
const height = ref('0px');
const applyTransitions = ref(false);
const attrs = computed(() => {
  if (props.component === 'divider') {
    return {
      'aria-hidden': true,
    };
  }

  if (props.component === 'link') {
    return {
      to: props.link,
    };
  }

  if (props.component === 'button') {
    return {
      type: 'button',
    };
  }

  return {};
});
const element = computed(() => {
  if (props.component === 'link') {
    return resolveComponent('NuxtLink');
  }

  if (props.component === 'text') {
    return 'span';
  }

  if (props.component === 'divider') {
    return 'hr';
  }

  if (props.component === 'section') {
    return 'div';
  }

  return props.component;
});
const buttonEvents = computed(() => {
  if (props.component === 'button') {
    return {
      click: (event: MouseEvent) => emit('click', event),
    };
  }

  return {};
});
const hasIcon = computed(() => !!slots.icon);

function setHeight() {
  if (!itemRef.value) {
    return;
  }

  height.value = `${(itemRef.value.scrollHeight ?? 0) + 1}px`;
}

function resolveAnimations() {
  if (!props.expanded) {
    applyTransitions.value = true;

    return;
  }

  window.setTimeout(() => {
    applyTransitions.value = true;
  }, 201);
}

onMounted(() => {
  if (props.collapsible) {
    setHeight();

    resolveAnimations();
  }
});
</script>

<template>
  <component
    v-bind="attrs"
    :is="element"
    ref="itemRef"
    class="dropdown__item"
    :class="{
      'dropdown__item--active': active,
      'dropdown__item--collapsible': collapsible,
      'dropdown__item--animate': applyTransitions,
      'dropdown__item--expanded': expanded,
      [`dropdown__item--level-${level}`]: true,
      [`dropdown__item--${component}`]: true,
      'dropdown__item--not-activable': notActivable,
    }"
    :style="{ ...(collapsible ? { '--height': height } : {}) }"
    v-on="buttonEvents"
  >
    <div v-if="hasIcon" class="dropdown__icon">
      <slot name="icon" />
    </div>

    <slot />
  </component>
</template>

<style lang="scss" scoped>
.dropdown__item {
  --margin: 0;
  --prefix-width: 0;
  --suffix-width: 0;

  display: flex;
  column-gap: 0.5rem;
  align-items: center;

  margin: 0;
  margin-inline: var(--margin);
  padding: 0.5rem;

  border-width: 0;
  background-color: transparent;
  border-radius: 0.375rem;

  text-align: left;
  font-weight: 500;
  color: var(--black);
  text-decoration: none;

  .dropdown__icon {
    display: block;
  }

  &.dropdown__item--active:not(.dropdown__item--not-activable),
  &.router-link-exact-active:not(.dropdown__item--not-activable) {
    background-color: var(--red-lightest);

    color: var(--red-basic);
    font-weight: 600;
  }

  &:not(
      hr,
      span,
      .dropdown__item--active,
      .router-link-exact-active,
      .dropdown__item--section
    ):hover {
    background-color: var(--white-darkened);
  }

  &.dropdown__item--level-2 {
    --margin: 0.5rem;
  }

  &.dropdown__item--level-3 {
    --margin: 1rem;
  }

  &.dropdown__item--text {
    font-weight: 700;
  }

  &.dropdown__item--button {
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
    }
  }

  &.dropdown__item--section {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    align-items: unset;
    flex: 1 0 auto;

    padding: 0;
  }

  span {
    font-weight: inherit;
  }

  span + svg {
    margin-left: 0.25rem;
  }

  &.dropdown__item--section.dropdown__item--collapsible {
    margin-bottom: -0.25rem;

    height: 0;
    max-height: 0;

    overflow: hidden;
  }

  &.dropdown__item--section.dropdown__item--expanded {
    margin-bottom: 0;

    height: var(--height);
    max-height: var(--height);
  }

  &.dropdown__item--collapsible.dropdown__item--animate {
    @media (prefers-reduced-motion: no-preference) {
      transition:
        margin-top 0.25s ease-in-out,
        height 0.2s ease-in-out,
        max-height 0.2s ease-in-out;
      will-change: margin-top, height, max-height;
    }
  }
}

.dropdown__icon {
  align-self: flex-start;

  width: 1.5rem;
  height: 1.5rem;

  svg {
    margin: 0;
  }
}

hr.dropdown__item {
  margin-left: var(--margin);
  margin-right: var(--margin);
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
  padding: 0;

  border-width: 0.0625rem;
  background-color: currentColor;

  color: var(--gray-dark);
}
</style>
